import styled from 'styled-components'

interface Props {
  irxMode?: boolean
}

export default styled.img<Props>`
  height: 30px;
  width: auto;
  margin-right: 10px;
  margin-bottom: 1px;
`
