import styled from 'styled-components'

import CONFIG from '../../config'
import { media } from '../../lib/cssHelpers'

interface Props {
  irxMode?: boolean
}

export default styled.div<Props>`
  padding: 40px 40px 40px 40px;
  pointer-events: none;
  height: ${CONFIG.HEADER_HEIGHT}px;
  box-sizing: border-box;
  width: 100%;
  z-index: 99;
  ${media.phone`
    padding: 40px 40px 40px 25px;
  `};
`
